import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';

import media from '../../utils/media';
import config from '../../config';

const { colors, fonts } = config;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  margin-bottom: ${rem('24px')};
  width: 25%;

  &:last-child {
    margin-bottom: 0;
  }

  ${media.medium`
    width: 33.33%;
  `}
  ${media.small`
    width: 50%;
  `}
`;

const TopContent = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  margin-bottom: ${rem('8px')};

  svg {
    display: flex;
    width: 100px;
    width: ${rem('100px')};
    height: 100px;
    fill: ${props => props.fill};
  }
`;

TopContent.propTypes = {
  fill: PropTypes.string,
};

TopContent.defaultProps = {
  fill: colors.dark || '#fff',
};

const BottomContent = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.h1`
  display: flex;
  margin: 0;
  font-size: 24px;
  font-size: ${rem('24px')};
  text-align: center;
  line-height: 1;
`;

Title.propTypes = {
  fontFamily: PropTypes.string,
};

Title.defaultProps = {
  fontFamily: fonts.accentFontFamily || 'unset',
};

const IconListElement = ({
  Icon,
  titleAs,
  children,
}) => (
  <Container>
    <TopContent>
      <Icon />
    </TopContent>
    <BottomContent>
      <Title as={titleAs}>
        {children}
      </Title>
    </BottomContent>
  </Container>
);

IconListElement.propTypes = {
  Icon: PropTypes.func.isRequired,
  titleAs: PropTypes.string,
  children: PropTypes.string,
};

IconListElement.defaultProps = {
  titleAs: 'h1',
  children: '',
};

export default IconListElement;
