import React from 'react';
import styled from 'styled-components';

import DrivewayIcon from '../../assets/svg/driveway-icon.svg';
import GarageFloorIcon from '../../assets/svg/garage-floor-icon.svg';
import StairsIcon from '../../assets/svg/stairs-icon.svg';
import SidewalkIcon from '../../assets/svg/sidewalk-icon.svg';
import PatioIcon from '../../assets/svg/patio-icon.svg';
import CurbIcon from '../../assets/svg/curb-icon.svg';
import FoundationIcon from '../../assets/svg/foundation-icon.svg';

import IconListElement from './IconListElement';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const IconList = () => (
  <Container>
    <IconListElement
      titleAs="h3"
      Icon={DrivewayIcon}
    >
      Driveways
    </IconListElement>
    <IconListElement
      titleAs="h3"
      Icon={GarageFloorIcon}
    >
      Garage Floors
    </IconListElement>
    <IconListElement
      titleAs="h3"
      Icon={StairsIcon}
    >
      Stairs
    </IconListElement>
    <IconListElement
      titleAs="h3"
      Icon={SidewalkIcon}
    >
      Side Walks
    </IconListElement>
    <IconListElement
      titleAs="h3"
      Icon={PatioIcon}
    >
      Patios
    </IconListElement>
    <IconListElement
      titleAs="h3"
      Icon={CurbIcon}
    >
      Curbs
    </IconListElement>
    <IconListElement
      titleAs="h3"
      Icon={FoundationIcon}
    >
      Foundations
    </IconListElement>
  </Container>
);

export default IconList;
