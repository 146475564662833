import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';

import { Section } from '../Layout';
import Heading from '../Text';
import PreviousButton from './PreviousButton';
import NextButton from './NextButton';
import Slide1 from '../../assets/images/slide-1.jpg';
import Slide2 from '../../assets/images/slide-2.jpg';
import Slide3 from '../../assets/images/slide-3.jpg';
import Slide4 from '../../assets/images/slide-4.jpg';

import media from '../../utils/media';
import config from '../../config';

const { Column } = config.components;

const SliderSection = styled(Section)`
  min-height: 300px;
  min-height: ${rem('300px')};
  background-image: url('${props => props.backgroundImage}');
  background-position: center;
  background-size: cover;
  transition: background-image 2s;
`;

SliderSection.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
};

const ContentBox = styled.div`
  display: flex;
  flex: 1;
`;

const LeftContent = styled.div`
  display: flex;
  flex-grow: 0;
  justify-content: flex-start;
`;

const CenterContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 16px;
  padding: ${rem('16px')};

  ${media.small`
    h2 {
      font-size: 24px;
      font-size: ${rem('24px')};
    }
  `}
`;

const RightContent = styled.div`
  display: flex;
  flex-grow: 0;
  justify-content: flex-end;
`;

class Slider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slides: [
        {
          id: 0,
          url: Slide1,
        },
        {
          id: 1,
          url: Slide2,
        },
        {
          id: 2,
          url: Slide3,
        },
        {
          id: 3,
          url: Slide4,
        },
      ],
      currentSlide: 0,
    };
  }

  goToPrevSlide = () => {
    const { state } = this;

    // if we're at the first slide, then the previous button should setState to
    // the last slide
    if (state.currentSlide === 0) {
      return this.setState({
        currentSlide: state.slides.length - 1,
      });
    }

    return this.setState(prevState => ({
      currentSlide: prevState.currentSlide - 1,
    }));
  }

  goToNextSlide = () => {
    const { state } = this;

    // if we're at the last slide, then the next button should setState to
    // the first slide
    if (state.currentSlide === state.slides.length - 1) {
      return this.setState({
        currentSlide: 0,
      });
    }

    return this.setState(prevState => ({
      currentSlide: prevState.currentSlide + 1,
    }));
  }

  slideWidth = () => (document.querySelector('.slide').clientWidth)

  getCurrentImage = () => {
    const { state } = this;
    return state.slides[state.currentSlide].url;
  }

  render() {
    return (
      <SliderSection
        backgroundImage={this.getCurrentImage()}
        containerPaddingX="0px"
        containerPaddingY="0px"
        rowBackgroundColor="rgba(0, 0, 0, 0.5)"
        columnMaxWidth={Column.maxWidth}
      >
        <ContentBox>
          <LeftContent>
            <PreviousButton
              goToPrevSlide={this.goToPrevSlide}
            />
          </LeftContent>
          <CenterContent>
            <Heading
              as="h2"
              marginBottom="0px"
              color="#fff"
              fontSize="32px"
              textAlign="center"
            >
              A full-service concrete construction company specializing in flatwork and
               foundations
            </Heading>
          </CenterContent>
          <RightContent>
            <NextButton
              goToNextSlide={this.goToNextSlide}
            />
          </RightContent>
        </ContentBox>
      </SliderSection>
    );
  }
}

export default Slider;
