import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';

import config from '../../config';

const { colors } = config;

const Input = styled.input.attrs(() => ({
  size: '1',
}))`
  display: flex;
  padding: 8px;
  padding: ${rem('8px')};
  margin-bottom: 10px;
  margin-bottom: ${rem('10px')};
  border: 1px solid #aaa;
  border-radius: 4px;
  border-radius: ${rem('4px')};
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  color: #1a1a1a;
  background-color: #fff;
  font-size: 16px;
  font-size: ${rem('16px')};

  &::placeholder {
    color: #555;
  }
  &:focus {
    box-shadow: inset 0 0 2px rgba(26, 26, 26, 0.4), 0 0 0 4px ${props => props.focusBoxShadowColor};
    outline: 0;
  }
`;

Input.propTypes = {
  focusBoxShadowColor: PropTypes.string,
};

Input.defaultProps = {
  focusBoxShadowColor: colors.transparentAccentColor || 'rgba(26, 26, 26, 0.4)',
};


export default Input;
