import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';

const Heading = styled.h1`
  color: ${props => props.color};
  font-size: ${props => rem(props.fontSize)};
  text-align: ${props => props.textAlign};
  line-height: 1;
  margin: 0;
  margin-bottom: ${props => rem(props.marginBottom)};
`;

Heading.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.string,
  textAlign: PropTypes.string,
  marginBottom: PropTypes.string,
};

Heading.defaultProps = {
  color: '#222',
  fontSize: '36px',
  textAlign: 'left',
  marginBottom: '32px',
};

export default Heading;
