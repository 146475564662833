import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';

import LeftChevronIcon from '../../assets/svg/left-chevron-icon.svg';
import config from '../../config';

const { colors } = config;

const Container = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  padding: 16px;
  padding: ${rem('16px')};

  svg {
    display: flex;
    width: auto;
    height: 32px;
    height: ${rem('32px')};
    fill: rgba(255, 255, 255, 0.5);
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);

    svg {
      fill: ${props => props.hoverFill}
    }
  }
`;

Container.propTypes = {
  hoverFill: PropTypes.string,
};

Container.defaultProps = {
  hoverFill: colors.accentColor || '#eee',
};


const PreviousButton = ({
  goToPrevSlide,
}) => (
  <Container onClick={goToPrevSlide}>
    <LeftChevronIcon />
  </Container>
);

PreviousButton.propTypes = {
  goToPrevSlide: PropTypes.func.isRequired,
};

export default PreviousButton;
