import styled from 'styled-components';
import PropTypes from 'prop-types';
import { darken } from 'polished';

import Input from './Input';
import config from '../../config';

const { colors } = config;

const SubmitInput = styled(Input).attrs(() => ({
  type: 'submit',
}))`
  border: none;
  box-shadow: none;
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  font-weight: 600;
  justify-content: center;

  &:hover {
    cursor: pointer;
    background-color: ${props => darken(0.05, props.backgroundColor)};
  }
  &:focus {
    box-shadow: 0 0 0 4px ${props => props.focusBoxShadowColor};
  }
`;

SubmitInput.propTypes = {
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  focusBoxShadowColor: PropTypes.string,
};

SubmitInput.defaultProps = {
  color: '#fff',
  backgroundColor: colors.accentColor || '#222',
  focusBoxShadowColor: colors.transparentAccentColor || 'rgba(26, 26, 26, 0.4)',
};

export default SubmitInput;
