import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

import Logo1 from '../../assets/images/lawton-realty-logo_white.png';
import Logo2 from '../../assets/images/acm-logo_white.png';
import Logo3 from '../../assets/images/abc-property-managers-logo_white.png';

import media from '../../utils/media';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 33.33%;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  margin-bottom: ${rem('16px')};

  img {
    display: block;
    width: auto;
    max-width: 100%;
    max-height: 60px;
    max-height: ${rem('60px')};
  }

  ${media.medium`
    width: 50%;
  `}
  ${media.small`
    width: 100%;
  `}
`;

const ImageList = () => (
  <Container>
    <ImageWrapper>
      <img src={Logo1} alt="Lawton Realty Logo" />
    </ImageWrapper>
    <ImageWrapper>
      <img src={Logo2} alt="ACM Community Management Logo" />
    </ImageWrapper>
    <ImageWrapper>
      <img src={Logo3} alt="ABC Property Managers Logo" />
    </ImageWrapper>
  </Container>
);

export default ImageList;
