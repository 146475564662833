import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

import SEO from '../components/SEO';
import { Layout, Section } from '../components/Layout';
import { Row, Column } from '../components/Grid';
import IconList from '../components/IconList';
import ImageList from '../components/ImageList';
import Heading from '../components/Text';
import Slider from '../components/Slider';
import {
  Form,
  Input,
  TextArea,
  SubmitInput,
} from '../components/Form';

import media from '../utils/media';
import config from '../config';

const { colors, components } = config;
const { Column: ColumnConfig } = components;

const LeftContent = styled(Column)`
  flex-grow: 1;
  padding-right: 8px;
  padding-right: ${rem('8px')};
  color: #fff;

  p {
    text-align: center;
    margin-bottom: 0;
    color: #aaa;
  }

  ${media.large`
    flex-basis: 100%;
    padding-right: 0;

    p {
      margin-bottom: 16px;
      margin-bottom: ${rem('16px')};
    }
  `}
`;

const RightContent = styled(Column)`
  flex-grow: 0;
  padding-left: 8px;
  padding-left: ${rem('8px')};
  color: #fff;

  p {
    margin-bottom: 0;
  }

  ${media.large`
    flex-basis: 100%;
    padding-left: 0;
    text-align: center;
  `}
`;

const LeftFormContent = styled(Column)`
  padding-right: 5px;
  padding-right: ${rem('5px')};
  color: #fff;
`;

const RightFormContent = styled(Column)`
  padding-left: 5px;
  padding-left: ${rem('5px')};
  color: #fff;
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[]} />
    <Slider />
    <Section
      containerId="what-we-do"
      containerPaddingY="32px"
      containerBackgroundColor={colors.accentColor}
      columnMaxWidth={ColumnConfig.maxWidth}
    >
      <Heading
        as="h2"
        color={colors.dark}
        fontSize="40px"
        textAlign="center"
      >
        What We Do
      </Heading>
      <IconList />
    </Section>
    <Section
      containerId="who-we-work-with"
      containerPaddingY="32px"
      containerBackgroundColor={colors.dark}
      columnMaxWidth={ColumnConfig.maxWidth}
    >
      <Heading
        as="h2"
        color={colors.accentColor}
        fontSize="40px"
        textAlign="center"
      >
        Who We Work With
      </Heading>
      <ImageList />
    </Section>
    <Section
      containerId="who-we-are"
      containerPaddingY="32px"
      containerBackgroundColor="#fff"
      columnMaxWidth={ColumnConfig.maxWidth}
    >
      <Heading
        as="h2"
        color={colors.accentColor}
        fontSize="40px"
        textAlign="center"
      >
        Who We Are
      </Heading>
      <Heading
        as="h3"
        color={colors.dark}
        fontSize="24px"
        marginBottom="16px"
      >
        {`
          Hi there! We're Buttitta Construction.
        `}
      </Heading>
      <p>
        {`
          We're a concrete construction company with over 15 years of experience.
          Day in, day out, we work with homeowners, as well as property management
          companies to design, build, and turn their dream projects into a reality.
          We bring experience and passion to each job because customer satisfaction
          is our number one priority.
        `}
      </p>
    </Section>
    <Section
      containerId="lets-connect"
      containerPaddingY="32px"
      containerBackgroundColor={colors.dark}
      columnMaxWidth={ColumnConfig.maxWidth}
    >
      <Heading
        as="h2"
        color={colors.accentColor}
        fontSize="40px"
        textAlign="center"
      >
        {"Let's Connect"}
      </Heading>
      <Row>
        <LeftContent>
          <Form name="contact" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="contact" />
            <Input name="fullName" placeholder="Full Name" />
            <Row>
              <LeftFormContent>
                <Input name="phoneNumber" placeholder="Phone Number" />
              </LeftFormContent>
              <RightFormContent>
                <Input name="email" placeholder="Email Address" />
              </RightFormContent>
            </Row>
            <TextArea name="message" placeholder="Message..." />
            <SubmitInput value="Submit" />
          </Form>
          <p>
            {"This form automatically sends an email to Buttitta Construction's primary inbox."}
          </p>
        </LeftContent>
        <RightContent>
          <Heading
            as="h3"
            color="#fff"
            fontSize="24px"
            textAlign="center"
            marginBottom="16px"
          >
            Business Contact Information
          </Heading>
          <p>
            Owner: Anthony Buttitta
            <br />
            Phone: (630) 973-3186
            <br />
            Email: contact@buttittaconstruction.com
          </p>
        </RightContent>
      </Row>
    </Section>
  </Layout>
);

export default IndexPage;
