import styled from 'styled-components';
import { rem } from 'polished';

import Input from './Input';

const TextArea = styled(Input).attrs(() => ({
  as: 'textarea',
}))`
  min-height: ${rem('64px')};
  max-height: ${rem('200px')};
  resize: vertical;
`;

export default TextArea;
